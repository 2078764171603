import './bidsOverviewDataGrid.css'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Stack } from '@mui/material'
import type { GridColDef, GridFilterModel, GridPaginationModel, GridRowParams } from '@mui/x-data-grid'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import type { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomChip from '@/components/dataDisplay/CustomChip'
import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { COLUMN_WITH_MEDIUM_ICON_WIDTH } from '@/constants/layout'
import type { ActivationGroup } from '@/features/activationGroup/types'
import BidsFilterToolbar from '@/features/bidding/components/BidsFilterToolbar'
import BidsMenu from '@/features/bidding/components/overview/bidsMenu/BidsMenu'
import BidStatusesChip from '@/features/bidding/components/overview/BidStatusesChip'
import type { MarketProgram, Status } from '@/features/bidding/constants'
import { GET_BIDS_API_STALE_TIME } from '@/features/bidding/endpoints/bids'
import { useGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import type { Portfolio } from '@/features/bidding/types/bid'
import type { GroupedBid } from '@/features/bidding/types/groupedBid'
import { isManuallyAcceptable, isManuallyEditable } from '@/features/bidding/utils/bidStatus'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { buildBidCapacityLink, buildBidViewLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { buildBidOverviewRowItem } from '@/features/bidding/utils/model/buildBidOverviewRowItem'

type BidsOverviewDataGridProps = {
  portfolios: Portfolio[] | null
  activationGroups: ActivationGroup[] | null
  selectedCountry: CountryIdentifier
  filterModel: GridFilterModel
  paginationModel: GridPaginationModel
  setPaginationModel: (paginationModel: GridPaginationModel) => void
  setFilterModel: (filterModel: GridFilterModel) => void
}

export type BidOverviewRowItem = {
  portfolio: Portfolio
  marketProgram: MarketProgram
  activationGroupCodes: string[]
  deliveryDay: MarketDate
  createdAt: DateTime
  averageOfferedCapacity: number
  averageAcceptedCapacity: number
  totalOfferedCapacity: number
  totalAcceptedCapacity: number
  uniqueStatuses: Status[]
}

const BidsOverviewDataGrid = ({
  portfolios,
  activationGroups,
  selectedCountry,
  paginationModel,
  setPaginationModel,
  filterModel,
  setFilterModel,
}: Readonly<BidsOverviewDataGridProps>) => {
  const { t } = useTranslation()
  const { groupedBidsPage, isLoading } = useGroupedBidsPageQuery(
    { country: selectedCountry, filter: filterModel, pagination: paginationModel },
    { staleTime: GET_BIDS_API_STALE_TIME },
  )

  const totalCount = groupedBidsPage?.totalCount
  const groupedBids = groupedBidsPage?.groupedBids

  return (
    <CustomDataGrid
      disableColumnSorting
      clickableRows={{
        navigateTo: (params: GridRowParams<BidOverviewRowItem>) =>
          buildBidViewLink({
            deliveryDay: params.row.deliveryDay,
            portfolioCode: params.row.portfolio.code,
            marketProgram: params.row.marketProgram,
          }),
      }}
      columnGroupingModel={getColumnGrouping(t)}
      columns={getColumns(t)}
      filterMode="server"
      filterModel={filterModel}
      getRowId={(row: BidOverviewRowItem) => row.deliveryDay.toISODate() + row.portfolio.code + row.marketProgram}
      isLoading={isLoading}
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount ?? 0}
      rows={groupedBids && activationGroups ? buildRowItems(groupedBids, activationGroups) : []}
      slotProps={{
        toolbar: {
          portfolios,
        },
      }}
      slots={{ toolbar: BidsFilterToolbar }}
      onFilterModelChange={setFilterModel}
      onPaginationModelChange={setPaginationModel}
    />
  )
}

const getColumns = (t: TFunction): GridColDef<BidOverviewRowItem>[] => [
  {
    field: 'portfolio',
    type: 'singleSelect',
    headerName: t('bidding.overview.header.portfolio'),
    valueFormatter: (value: Portfolio) => value.name,
    flex: 3,
  },
  {
    field: 'marketProgram',
    type: 'singleSelect',
    headerName: t('bidding.overview.header.market_program'),
    flex: 3,
    valueFormatter: (value: BidOverviewRowItem['marketProgram']) => t(`bidding.market_program.${value}`),
  },
  {
    field: 'activationGroupCodes',
    type: 'singleSelect',
    headerName: t('bidding.overview.header.activation_groups'),
    flex: 1,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) => (
      <CustomChip
        color="primary"
        label={params.row.activationGroupCodes.length}
        size="small"
        title={params.row.activationGroupCodes.toSorted((a, b) => a.localeCompare(b)).join(', ')}
        variant="filled"
      />
    ),
  },
  {
    field: 'deliveryDay',
    headerName: t('bidding.overview.header.delivery_day'),
    flex: 2,
    valueFormatter: (value: MarketDate) => value.getStartOfDay().toFormat('dd LLL, yyyy'),
  },
  {
    field: 'createdAt',
    headerName: t('bidding.overview.header.created_at'),
    flex: 2,
    valueFormatter: (value: DateTime) => value.toFormat('dd LLL, yyyy'),
  },
  {
    field: 'averageOfferedCapacity',
    headerName: t('bidding.overview.header.offered'),
    flex: 2,
    valueFormatter: (value: number) => convertToRoundedMw(value) + ' MW',
    headerClassName: 'average-bid-column',
    cellClassName: 'average-bid-column',
  },
  {
    field: 'averageAcceptedCapacity',
    headerName: t('bidding.overview.header.accepted'),
    flex: 2,
    valueFormatter: (value: number) => convertToRoundedMw(value) + ' MW',
    headerClassName: 'average-bid-column divider',
    cellClassName: 'average-bid-column divider',
  },
  {
    field: 'totalOfferedCapacity',
    headerName: t('bidding.overview.header.offered'),
    flex: 2,
    valueFormatter: (value: number) => convertToRoundedMw(value) + ' MW',
    headerClassName: 'totals-bid-column',
    cellClassName: 'totals-bid-column',
  },
  {
    field: 'totalAcceptedCapacity',
    headerName: t('bidding.overview.header.accepted'),
    flex: 2,
    valueFormatter: (value: number) => convertToRoundedMw(value) + ' MW',
    headerClassName: 'totals-bid-column',
    cellClassName: 'totals-bid-column',
  },
  {
    field: 'status',
    headerName: t('bidding.overview.header.status'),
    flex: 3,
    minWidth: 170,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) => (
      <BidStatusesChip statuses={params.row.uniqueStatuses} />
    ),
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    width: COLUMN_WITH_MEDIUM_ICON_WIDTH * 1.5,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) => <GridActionsColumn params={params} />,
  },
]

const getColumnGrouping = (t: TFunction) => [
  {
    groupId: t('bidding.overview.header.ptu_average'),
    children: [{ field: 'averageOfferedCapacity' }, { field: 'averageAcceptedCapacity' }],
    headerClassName: 'average-bid-column divider',
  },
  {
    groupId: t('bidding.overview.header.totals'),
    children: [{ field: 'totalOfferedCapacity' }, { field: 'totalAcceptedCapacity' }],
    headerClassName: 'totals-bid-column',
  },
]

const buildRowItems = (groupedBids: GroupedBid[], activationGroups: ActivationGroup[]) =>
  groupedBids.map((groupedBid) => buildBidOverviewRowItem(groupedBid, activationGroups))

const GridActionsColumn = ({ params }: { params: GridRenderCellParams<BidOverviewRowItem> }) => {
  const navigate = useNavigate()

  return (
    <Stack sx={{ flexDirection: 'row' }}>
      <Box minWidth="50%">
        {isBidEditable(params.row) && (
          <CustomIconButton
            Icon={EditOutlinedIcon}
            aria-label="edit"
            onClick={(evt: MouseEvent<HTMLButtonElement>) => {
              evt.preventDefault()

              navigate(
                buildBidCapacityLink({
                  deliveryDay: params.row.deliveryDay,
                  portfolioCode: params.row.portfolio.code,
                  marketProgram: params.row.marketProgram,
                }),
              )
            }}
          />
        )}
      </Box>
      <Box>
        {isMenuVisible(params.row) && (
          <BidsMenu
            groupedBidParams={{
              deliveryDay: params.row.deliveryDay,
              portfolioCode: params.row.portfolio.code,
              marketProgram: params.row.marketProgram,
            }}
            statuses={params.row.uniqueStatuses}
          />
        )}
      </Box>
    </Stack>
  )
}

const isBidEditable = (rowItem: BidOverviewRowItem) => rowItem.deliveryDay >= new MarketDate().plus({ days: 1 })

const isMenuVisible = (rowItem: BidOverviewRowItem) => {
  return (
    rowItem.uniqueStatuses.some((status) => isManuallyAcceptable(status) || isManuallyEditable(status)) &&
    (rowItem.marketProgram === 'NORDICS_FCRD_DOWN_STATIC' ||
      rowItem.marketProgram === 'NORDICS_FCRD_UP_STATIC' ||
      rowItem.marketProgram === 'NORDICS_FCRD_DOWN_DYNAMIC' ||
      rowItem.marketProgram === 'NORDICS_FCRD_UP_DYNAMIC')
  )
}

export default BidsOverviewDataGrid
