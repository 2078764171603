import { Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import ForecastXyBaselineChart from '@/features/report/components/ForecastXyBaselineChart'
import LiveXyBaselineChart from '@/features/report/components/LiveXyBaselineChart'
import ReportsAppBar from '@/features/report/components/ReportsAppBar'
import RetrospectXyBaselineChart from '@/features/report/components/RetrospectXyBaselineChart'

const PORTFOLIOS = ['GR-OPTIMUS-XY']
const TIMEZONE_EUROPE_ATHENS = 'Europe/Athens'

export const Reports = () => {
  const { t } = useTranslation()

  const [selectedPortfolio, setSelectedPortfolio] = useState<string>(PORTFOLIOS[0])

  return (
    <>
      <PageHeader
        appBarContent={
          <ReportsAppBar
            portfolioOptions={PORTFOLIOS}
            selectedPortfolio={selectedPortfolio}
            setSelectedPortfolio={setSelectedPortfolio}
          />
        }
        breadcrumbItems={[]}
        pageTag={'reports'}
        pageTitle={t('component.page_header.reports')}
      />
      <Stack direction="column" marginY={3} spacing={3}>
        <ForecastXyBaselineChart portfolio={selectedPortfolio} timezone={TIMEZONE_EUROPE_ATHENS} />
        <LiveXyBaselineChart portfolio={selectedPortfolio} timezone={TIMEZONE_EUROPE_ATHENS} />
        <RetrospectXyBaselineChart portfolio={selectedPortfolio} timezone={TIMEZONE_EUROPE_ATHENS} />
      </Stack>
    </>
  )
}
