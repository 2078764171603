import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { z } from 'zod'

const luxonDateTime = z.preprocess((arg) => {
  if (arg instanceof DateTime) {
    return arg.toJSDate()
  }
  return arg
}, z.date())

export const ResourceSteeringDefaultsSchema = z.object({
  defaultConsumptionLevel: z.number().nullable(),
  steeringDefaultType: z.enum(['NONE', 'SET_TO_DEFAULT_LEVEL', 'RELEASE_CONTROL']),
})

export const SteeringCapabilitiesSchema = z.object({
  isReleaseControlSupported: z.boolean(),
  minReleaseControlTimeInSeconds: z.number().nullable(),
})

const SteeringRangeEntrySchema = z
  .object({
    valueType: z.enum(['ABSOLUTE', 'DYNAMIC']),
    value: z.number().nullable(),
    minSecondsOnThisLevel: z.number().nullable(),
    maxSecondsOnThisLevel: z.number().nullable(),
  })
  .refine(
    (data) => {
      if (data.valueType === 'ABSOLUTE' && data.value === null) {
        return false
      }
      return true
    },
    {
      message: 'Value must be specified for ABSOLUTE value type',
      path: ['value'],
    },
  )

export const ResourceSteeringRangeSchema = z
  .object({
    max: SteeringRangeEntrySchema,
    min: SteeringRangeEntrySchema,
    step: SteeringRangeEntrySchema,
  })
  .refine(
    (data) => {
      return (
        data.max.valueType === 'ABSOLUTE' && data.min.valueType === 'ABSOLUTE' && data.step.valueType === 'ABSOLUTE'
      )
    },
    {
      message: 'All values must be of ABSOLUTE type, we have deprecated DYNAMIC values',
      path: ['maxmin'],
    },
  )

export type ResourceSteeringRangeSchemaType = z.infer<typeof ResourceSteeringRangeSchema> & {
  maxmin?: { message: string }
}

export const ManualSteeringFormSchema = z
  .object({
    steeringCommandType: z.enum(['SET_TARGET_CONSUMPTION_LEVEL', 'RELEASE_CONTROL']),
    targetConsumptionLevel: z.number().nullable(),
    steerUntil: luxonDateTime.nullable(),
    force: z.boolean().default(false),
  })
  .refine(
    (data) => {
      if (data.steeringCommandType === 'SET_TARGET_CONSUMPTION_LEVEL' && data.targetConsumptionLevel === null) {
        return false
      }
      return true
    },
    {
      message: 'Target consumption level must be specified for SET_TARGET_CONSUMPTION_LEVEL command type',
      path: ['targetConsumptionLevel'],
    },
  )
  .refine(
    (data) => {
      if (data.steerUntil) {
        return data.steerUntil > new Date()
      }
      return true
    },
    {
      message: 'Steer until date must be in the future',
      path: ['steerUntil'],
    },
  )

export const getUpsertResourceScheduleSchema = (t: TFunction) =>
  z.object({
    scheduleJson: z
      .string()
      .min(1, t('resources.scheduler.upsert_schedule_form.schedule_json_required_error'))
      .transform((val) => {
        try {
          return JSON.parse(val)
        } catch (e) {
          console.error('JSON parsing error:', e)
          throw new Error(t('resources.scheduler.upsert_schedule_form.schedule_json_parse_error'))
        }
      }),
  })
