import {
  AccountCircleOutlined,
  BookOutlined,
  ContactsOutlined,
  FeedbackOutlined,
  GroupWorkOutlined,
  UpdateOutlined,
} from '@mui/icons-material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import GraphicEqIcon from '@mui/icons-material/GraphicEq'
import PeopleIcon from '@mui/icons-material/People'
import { Box, Divider, Drawer, Link as MuiLink, List, Stack } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import PoweredBySympowerLogo from '@/assets/powered-by-sympower-monochrome.svg'
import CustomTypography from '@/components/dataDisplay/CustomTypography'
import EnvironmentRibbon from '@/components/layouts/EnvironmentRibbon'
import CustomerNavBarItems from '@/components/navigation/CustomerNavBarItems'
import { USER_ROLES } from '@/constants/userRoles'
import environment from '@/environment'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'
import { DEFAULT_WHITELABEL_CONFIG } from '@/features/whitelabel/default'
import { useMuiTheme } from '@/features/whitelabel/hooks/useMuiTheme'

import ExperimentsSection from './ExperimentsSection'
import ListItemLink from './ListItemLink'

type NavBarProps = {
  drawerWidth: number
}

function NavBar({ drawerWidth }: Readonly<NavBarProps>) {
  const location = useLocation()
  const { t } = useTranslation()
  const { branding, palette, mailto } = useWhitelabelConfigurationContext()
  const muiTheme = useMuiTheme()
  const version = environment.deployedVersion
  const { isEnabled } = useFeatureToggle()
  const { permissions } = usePermissions()
  const { loggedInUser } = useAuth()

  const hasCustomerUserRole = loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value
  const hasCustomerManagerUserRole = loggedInUser?.role === USER_ROLES.CUSTOMER_MANAGERS.value
  const hasAdminRole = loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value

  const navbarTheme = createTheme({
    ...muiTheme.components,
    ...muiTheme.typography,
    palette: {
      mode: 'dark', // This is preferred so that all components within the sidebar (fields + text) to invert their color to suit the dark background
      background: {
        paper: palette.sidebar.background,
      },
      action: {
        active: 'rgba(255, 255, 255, 0.56)',
      },
      text: {
        primary: palette.sidebar.text, // this could be removed, but it's here due to lack of a design system.
      },
    },
  })

  return (
    <ThemeProvider theme={navbarTheme}>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: muiTheme.transitions.create('width', {
              easing: muiTheme.transitions.easing.sharp,
              duration: muiTheme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
      >
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: muiTheme.spacing(2) }}>
              <Link to="/">
                <img alt="Logo" src={branding.monochromeLogo} style={{ width: '100%' }} />
              </Link>
            </Box>
            <List component="nav" sx={{ marginTop: muiTheme.spacing(2) }}>
              {hasCustomerUserRole ? (
                <CustomerNavBarItems />
              ) : (
                <>
                  {!hasCustomerManagerUserRole && (
                    <ListItemLink
                      icon={<DashboardIcon />}
                      isActive={location.pathname === '/'}
                      primary={t('component.navbar.dashboard')}
                      to="/"
                    />
                  )}

                  {isEnabled('FLEXPORTAL_RESOURCES_PAGE_ENABLED') && permissions.has('activationGroups') && (
                    <ListItemLink
                      icon={<ElectricalServicesIcon />}
                      isActive={location.pathname === '/resources'}
                      primary={t('component.navbar.resources')}
                      to="/resources"
                    />
                  )}

                  {isEnabled('FLEXPORTAL_BIDDING_PAGE_ENABLED') && permissions.has('bidding') && (
                    <ListItemLink
                      icon={<UpdateOutlined />}
                      isActive={location.pathname === '/bidding' || location.pathname === '/bidding/create'}
                      primary={t('component.navbar.bidding')}
                      to="/bidding"
                    />
                  )}

                  {isEnabled('FLEXPORTAL_REPORTS_PAGE_ENABLED') && permissions.has('reports') && (
                    <ListItemLink
                      icon={<BookOutlined />}
                      isActive={location.pathname === '/reports'}
                      primary={t('component.navbar.reports')}
                      to="/reports"
                    />
                  )}

                  {isEnabled('FLEXPORTAL_ACTIVATION_GROUPS_PAGE_ENABLED') && permissions.has('activationGroups') && (
                    <ListItemLink
                      icon={<GroupWorkOutlined />}
                      isActive={location.pathname === '/activation-groups'}
                      primary={t('component.navbar.activation_groups')}
                      to="/activation-groups"
                    />
                  )}

                  {isEnabled('FLEXPORTAL_DISTURBANCES_PAGE_ENABLED') && permissions.has('disturbances') && (
                    <ListItemLink
                      icon={<GraphicEqIcon />}
                      isActive={location.pathname === '/disturbances'}
                      primary={t('component.navbar.disturbances')}
                      to="/disturbances"
                    />
                  )}
                  <Divider />
                  {isEnabled('FLEXPORTAL_CUSTOMERS_PAGE_ENABLED') && permissions.has('customerDetails') && (
                    <ListItemLink
                      icon={<ContactsOutlined />}
                      isActive={location.pathname === '/customers'}
                      primary={t('component.navbar.customers')}
                      to="/customers"
                    />
                  )}
                  {isEnabled('FLEXPORTAL_PARTNERS_PAGE_ENABLED') && permissions.has('partners') && (
                    <ListItemLink
                      icon={<PeopleIcon />}
                      isActive={location.pathname === '/partners'}
                      primary={t('component.navbar.partners')}
                      to="/partners"
                    />
                  )}
                  {permissions.has('users') && (
                    <ListItemLink
                      icon={<AccountCircleOutlined />}
                      isActive={location.pathname === '/users' || location.pathname === '/user/create'}
                      primary={t('component.navbar.users')}
                      to="/users"
                    />
                  )}
                  {environment.environmentName !== 'production' && (
                    <>
                      <Divider />
                      <ExperimentsSection />
                    </>
                  )}
                </>
              )}
            </List>
          </Box>

          <Stack
            sx={{
              direction: 'column',
              flexGrow: 1,
              justifyContent: 'flex-end',
              margin: 2,
              gap: 2,
            }}
          >
            <Stack direction="row" gap={1} justifyContent={'flex-start'}>
              <FeedbackOutlined sx={{ color: '#ffc107' }} />
              <MuiLink color="#ffffff" href={`mailto:${mailto?.feedback}`}>
                {mailto?.feedback}
              </MuiLink>
            </Stack>
            {hasAdminRole && version && (
              <CustomTypography sx={{ opacity: 0.6 }} variant="body2">
                {t('common.version')} {version}
              </CustomTypography>
            )}
            {environment.environmentName !== 'production' && (
              <Box sx={{ marginBottom: muiTheme.spacing(1) }}>
                <EnvironmentRibbon name={environment.environmentName} />
              </Box>
            )}
            {branding.name !== DEFAULT_WHITELABEL_CONFIG.branding.name && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  alt="Powered by Sympower"
                  src={PoweredBySympowerLogo}
                  style={{ alignSelf: 'flex-end', width: '50%' }}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Drawer>
    </ThemeProvider>
  )
}

export default NavBar
