import { Divider } from '@mui/material'
import { useEffect, useState } from 'react'

import ResourceCard from '@/features/resource/components/ResourceCard'
import SteeringCapabilitiesPanel from '@/features/resource/components/steering/SteeringCapabilitiesPanel'
import SteeringDefaultsPanel from '@/features/resource/components/steering/SteeringDefaultsPanel'
import SteeringRangesPanel from '@/features/resource/components/steering/SteeringRangesPanel'
import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'
import type {
  ResourceSteeringConfig,
  ResourceSteeringDefaultsSchemaType,
  SteeringCapabilitiesSchemaType,
} from '@/features/resource/types'

export type ResourceSteeringModalProps = {
  resourceUuid: string
}

const ResourceSteeringCard = ({ resourceUuid }: ResourceSteeringModalProps) => {
  const { steeringConfig } = useResourceWithPollingQuery({ id: resourceUuid! })
  const [localSteeringConfig, setLocalSteeringConfig] = useState<ResourceSteeringConfig | undefined>(steeringConfig)

  useEffect(() => {
    setLocalSteeringConfig(steeringConfig)
  }, [steeringConfig])

  const updateSteeringDefaults = (data: ResourceSteeringDefaultsSchemaType) => {
    setLocalSteeringConfig((prevConfig) => {
      if (!prevConfig) return prevConfig
      return {
        ...prevConfig,
        steeringDefaultType: data.steeringDefaultType,
        defaultConsumptionLevel: data.defaultConsumptionLevel,
      }
    })
  }

  const updateSteeringCapabilities = (data: SteeringCapabilitiesSchemaType) => {
    setLocalSteeringConfig((prevConfig) => {
      if (!prevConfig) return prevConfig
      return {
        ...prevConfig,
        isReleaseControlSupported: data.isReleaseControlSupported,
        minReleaseControlTimeInSeconds: data.isReleaseControlSupported ? data.minReleaseControlTimeInSeconds : null,
      }
    })
  }

  return (
    <ResourceCard
      content={
        steeringConfig && (
          <>
            <SteeringCapabilitiesPanel
              resourceID={resourceUuid}
              steeringConfig={localSteeringConfig}
              onUpdateSteeringCapabilities={updateSteeringCapabilities}
            />
            <Divider />
            <SteeringDefaultsPanel
              resourceID={resourceUuid}
              steeringConfig={localSteeringConfig}
              onUpdateSteeringDefaults={updateSteeringDefaults}
            />
            <Divider />
            <SteeringRangesPanel resourceID={resourceUuid} steeringConfig={localSteeringConfig} />
          </>
        )
      }
      resourceUuid={resourceUuid}
    />
  )
}

export default ResourceSteeringCard
