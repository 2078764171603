import { WATTS_IN_MW } from '@/constants/units'
import type { PtuVolumes, Volume } from '@/features/bidding/types/bid'
import { BulkFillMode } from '@/features/bidding/types/bid'
import { roundWattsToNearestMwUnit } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { decimalAdjust } from '@/features/bidding/utils/calculations/decimalAdjust'

export const calculateBulkFillCapacity = (bulkMode: BulkFillMode, ptu: PtuVolumes, bulkFillValue: number) => {
  let newCapacity: number | null = null

  if (bulkMode === BulkFillMode.ABSOLUTE_VALUE) {
    newCapacity = decimalAdjust('round', bulkFillValue * WATTS_IN_MW, -1)
  } else if (bulkMode === BulkFillMode.FORECAST_RATIO) {
    newCapacity = calculateCapacityRatio(bulkFillValue, ptu.forecastedVolume)
  } else if (bulkMode === BulkFillMode.OFFER_RATIO) {
    newCapacity = calculateCapacityRatio(bulkFillValue, ptu.offeredVolume)
  } else if (bulkMode === BulkFillMode.ACCEPTED_RATIO) {
    newCapacity = calculateCapacityRatio(bulkFillValue, ptu.currentAcceptedVolume)
  }

  return newCapacity !== null ? roundWattsToNearestMwUnit(newCapacity) : null
}

const calculateCapacityRatio = (bulkFillValue: number, volume: Volume | undefined) =>
  volume ? volume.quantity * (bulkFillValue / 100) : null
