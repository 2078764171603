import type { ResourceLifecycleStage, ResourceType } from '@/features/resource/types'

/** Remove this constants when there is a way to get the list of resource types and lifecycle stages from the API */

export const LIFECYCLE_STAGES: ResourceLifecycleStage[] = [
  'DRAFT',
  'LIVE',
  'DECOMMISSIONED',
  'INTEGRATED',
  'VERIFIED',
  'UNKNOWN',
]

export const RESOURCE_TYPES: ResourceType[] = [
  'BEHIND_THE_METER_BATTERY',
  'CEMENT_MILL',
  'COAL_MILL',
  'COMPRESSOR',
  'CONDENSER',
  'CONVEYOR_BELT',
  'CRUSHER',
  'CYLINDER_ENGINE',
  'DEPOT_CHARGER',
  'DRYER',
  'ELECTRIC_BOILER',
  'ELECTRIC_FILTER',
  'ELECTRIC_MOTOR',
  'ELECTROLYSER',
  'FAN_HVAC',
  'FRONT_OF_THE_METER_BATTERY',
  'FURNACE',
  'GALVANISATION_POT',
  'GAS_ENGINE',
  'GAS_TURBINE',
  'GENERATOR',
  'HEATER',
  'HEAT_PUMP',
  'HIGH_PERFORMANCE_COMPUTER',
  'HYDROGEN_ELECTROLYSER',
  'HYDRO_POWER',
  'LIGHT',
  'OTHER',
  'PUBLIC_STREET_CHARGER',
  'PUMP',
  'RAIL_SWITCH_HEATER',
  'RAW_MILL',
  'RESIDENTIAL_PUBLIC_CHARGER',
  'SERVER',
  'SOLAR_PANEL',
  'STEAM_TURBINE',
  'STIRRER',
  'UNINTERRUPTIBLE_POWER_SYSTEM',
  'WIND_TURBINE',
  'WOOD_PULP_REFINER',
  'UNKNOWN',
]

export const ResourceRouteInformation = {
  INDEX: {
    label: 'component.page_header.resources',
    navigationPath: () => '/resources',
    routePath: '/',
  },
  RESOURCE_DETAILS: {
    label: 'component.page_header.resources',
    navigationPath: (resourceUuid: string) => `/resources/${resourceUuid}`,
    routePath: '/:resourceUuid',
  },
  RESOURCE_GENERAL: {
    label: 'component.page_header.resources',
    navigationPath: (resourceUuid: string) => `/resources/${resourceUuid}/general`,
    routePath: '/:resourceUuid/general',
  },
  RESOURCE_STEERING: {
    label: 'component.page_header.resources',
    navigationPath: (resourceUuid: string) => `/resources/${resourceUuid}/steering`,
    routePath: '/:resourceUuid/steering',
  },
  RESOURCE_BATTERY_STEERING: {
    label: 'component.page_header.resources',
    navigationPath: (resourceUuid: string, controlPortId: string) =>
      `/resources/${resourceUuid}/battery-steering/${controlPortId}`,
    routePath: '/:resourceUuid/battery-steering/:controlPortId',
  },
  RESOURCE_MANUAL_STEERING: {
    label: 'component.page_header.resources',
    navigationPath: (resourceUuid: string) => `/resources/${resourceUuid}/manual-steering`,
    routePath: '/:resourceUuid/manual-steering',
  },
  RESOURCE_SCHEDULER: {
    label: 'component.page_header.resources',
    navigationPath: () => `/resources/scheduler`,
    routePath: '/scheduler',
  },
} as const
