import { Box, FormHelperText, Grid, InputAdornment, Stack, TextField } from '@mui/material'
import { green } from '@mui/material/colors'
import type { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { INTERFACE_LANGUAGES } from '@/constants/interfaceLanguages'
import type { User } from '@/features/user/types/user'
import type { UserPersonalInfo } from '@/features/user/types/userPersonalInfo'

interface UserProfileFormProps {
  form: UseFormReturn<UserPersonalInfo, any, undefined>
  user: Partial<User>
}

export const UserProfileForm = ({ form, user }: UserProfileFormProps) => {
  const { t } = useTranslation()

  const userRole = user.role ?? null

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item md={6} xs={12}>
        <TextField disabled fullWidth label={t('common.username')} value={user.username} />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          disabled
          fullWidth
          label={t('user_profile.settings.form.role_label')}
          value={userRole ? t(`users.role.${userRole}`) : ''}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFieldController label={t('user_profile.settings.form.given_name_label')} name="givenName" />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFieldController label={t('user_profile.settings.form.family_name_label')} name="familyName" />
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack spacing={2}>
          <TextFieldController required label={t('user_profile.settings.form.email_label')} name="email" />

          <Box>
            <TextFieldController
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
              label={t('user_profile.settings.form.phone_label')}
              name="phone"
              required={user.isMFAEnabled}
              onChange={(event) => {
                // users are only allowed to enter numbers
                const value = event.target.value.replace(/\D/g, '')

                form.setValue('phone', value)
              }}
            />

            {!form.formState.errors.phone && user.isMFAEnabled && (
              <FormHelperText sx={{ color: green[500] }}>
                {t('user_profile.settings.form.mfa_phone_required_hint')}
              </FormHelperText>
            )}
          </Box>

          <SelectFieldController
            id="interface-language"
            label={t('user_form.form.interface_language_label')}
            name="uiLanguage"
            options={Object.values(INTERFACE_LANGUAGES).map((lang) => ({
              id: lang.value,
              value: lang.value,
              label: t(`common.interface_language.${lang.value}`),
            }))}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}
