import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import type { Localization } from '@mui/material/locale'
import { enUS, nlNL } from '@mui/material/locale'
import { ThemeProvider } from '@mui/material/styles'
import type { ReactNode } from 'react'

import { AlertProvider } from '@/contexts/AlertContext'
import { DEFAULT_WHITELABEL_CONFIG } from '@/features/whitelabel/default'
import { useMuiTheme } from '@/features/whitelabel/hooks/useMuiTheme'

const MUI_LOCALES: { [key: string]: Localization } = {
  en: enUS,
  nl: nlNL,
}

type PublicShellProps = {
  children?: ReactNode
}

function PublicShell({ children }: Readonly<PublicShellProps>) {
  const theme = useMuiTheme(MUI_LOCALES)
  const { branding } = DEFAULT_WHITELABEL_CONFIG

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <Box sx={{ mb: 2, display: 'flex' }}>
          <CssBaseline />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Container maxWidth="xl" sx={{ mt: 4 }}>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="center"
                sx={{ gap: 0, height: '100vh' }}
              >
                <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', marginTop: 4 }}>
                  <img
                    alt={`Logo ${branding.name}`}
                    src={branding.coloredLogo}
                    style={{ margin: '0 auto', width: '300px' }}
                  />
                </Box>
                {children}
              </Grid>
            </Container>
          </Box>
        </Box>
      </AlertProvider>
    </ThemeProvider>
  )
}

export default PublicShell
