import axios from 'axios'
import { DateTime } from 'luxon'

import environment from '@/environment'
import type { Column, FormattedRow, Response } from '@/features/bessDashboard/types'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export type Timeseries = {
  utcTimestamp: DateTime
  value: number
}[]

export async function getSolarActual(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getDatabricksActuals(
    'flexportal_sharing.bidding_insights.solar_actuals',
    priceArea,
    fromDeliveryDay,
    toDeliveryDay,
  )
}

export async function getSpotPriceActual(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getDatabricksActuals(
    'flexportal_sharing.bidding_insights.spot_price_actuals',
    priceArea,
    fromDeliveryDay,
    toDeliveryDay,
  )
}

export async function getSolarForecast(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getDatabricksForecasts(
    'flexportal_sharing.bidding_insights.solar_forecasts',
    priceArea,
    fromDeliveryDay,
    toDeliveryDay,
  )
}

export async function getSpotPriceForecast(
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
): Promise<Timeseries> {
  return await getDatabricksForecasts(
    'flexportal_sharing.bidding_insights.spot_price_forecasts',
    priceArea,
    fromDeliveryDay,
    toDeliveryDay,
  )
}

/**
 *  Actuals have the following properties:
 *    utc_timestamp: timestamp
 *    price_area: string
 *    value: number
 */
async function getDatabricksActuals(
  table: string,
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
) {
  const fromTimestamp = fromDeliveryDay.getStartOfDay().toUTC().toISO()!
  const toTimestamp = toDeliveryDay.getEndOfDay().toUTC().toISO()!
  const sqlQuery = `
    SELECT * FROM ${table}
    WHERE UPPER(price_area) = UPPER("${priceArea}")
    AND utc_timestamp BETWEEN "${fromTimestamp}" AND "${toTimestamp}"`
    .replace(/\s+/g, ' ')
    .trim()
  return requestTimeseries(sqlQuery)
}

/**
 *  Forecasts have the following properties:
 *    utc_timestamp: timestamp
 *    price_area: string
 *    value: number
 *    issue_date_ timestamp
 *  For each forecasted timestamp we can have multiple forecasts based on the issue_date (when the forecast was calculated).
 *  For each forecasted timestamp we need to get the latest forecast available, that is,
 *  for each utc_timestamp we need to select the row with the latest issue_date.
 */
async function getDatabricksForecasts(
  table: string,
  priceArea: string,
  fromDeliveryDay: MarketDate,
  toDeliveryDay: MarketDate,
) {
  const fromTimestamp = fromDeliveryDay.getStartOfDay().toUTC().toISO()!
  const toTimestamp = toDeliveryDay.getEndOfDay().toUTC().toISO()!
  const sqlQuery = `
    SELECT * FROM ${table}
    WHERE UPPER(price_area) = UPPER("${priceArea}")
    AND utc_timestamp BETWEEN "${fromTimestamp}" AND "${toTimestamp}"
    AND issue_date = (
        SELECT MAX(t2.issue_date)
        FROM ${table} t2
        WHERE t2.utc_timestamp = utc_timestamp
    )`
    .replace(/\s+/g, ' ')
    .trim()
  return requestTimeseries(sqlQuery)
}

async function requestTimeseries(sqlQuery: string) {
  const response: Response | undefined = await axios.get(
    `${environment.services.databricksSharingApiUrl}?sql-statement=${sqlQuery}`,
  )
  const rows = toFormattedRows(response)
  return toTimeseries(rows)
}

function toTimeseries(rows: FormattedRow[]): Timeseries {
  return rows.map((row) => ({
    utcTimestamp: toUtcDateTime(row['utc_timestamp'] as string),
    value: row['value'] as number,
  }))
}

function toFormattedRows(response: any | undefined): FormattedRow[] {
  if (!response) {
    return [] // Return an empty array if response is undefined
  }

  const content = response.data

  const { columns } = content.manifest.schema
  const data_array = content.result?.data_array ?? []

  return data_array.map((row: string[]) => {
    const formattedRow: FormattedRow = {}
    columns.forEach((col: Column, index: number) => {
      formattedRow[col.name] = row[index]
    })
    return formattedRow
  })
}

function toUtcDateTime(text: string) {
  return DateTime.fromISO(text, { zone: 'UTC' })
}
