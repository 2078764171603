import * as echarts from 'echarts'
import { DateTime } from 'luxon'

import type { FormattedRow } from '@/features/bessDashboard/types'

export function filterByMarketProgram(formattedData: FormattedRow[], marketProgramName: string): FormattedRow[] {
  return formattedData.filter((row) => row['market_program_name'] === marketProgramName)
}

export function filterByType(formattedData: FormattedRow[], type: string): FormattedRow[] {
  return formattedData.filter((row) => row['forecast_type'] === type)
}

export function formatUnderscoreString(input: any): any {
  if (typeof input !== 'string') {
    return input
  }

  return input.split('_').join(' ').toUpperCase()
}

export function findOverallMinMaxForColumns(data, col1, col2) {
  const values = data.flatMap((row) => [Number(row[col1]), Number(row[col2])]).filter((value) => !isNaN(value))

  return {
    overallMin: Math.min(...values),
    overallMax: Math.max(...values),
  }
}

export function constructDateString(date) {
  const dateInLuxon = DateTime.fromJSDate(date)

  const dayPart = dateInLuxon.toFormat('yyyy-MM-dd')
  const timePart = dateInLuxon.toFormat('HH:mm')

  return { dayPart, timePart }
}

export function getTimeZoneString() {
  // eslint-disable-next-line sonarjs/new-cap
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function customTooltipFormatter(params) {
  const DEFAULT_COLOR = '#000'

  const date = new Date(params[0].axisValue)
  const { dayPart, timePart } = constructDateString(date)

  const tooltipContent = `<strong>${dayPart} ${timePart} ${getTimeZoneString()}</strong><br>`

  return params.reduce((content, item, index) => {
    const seriesName = item.seriesName ?? `Series ${index + 1}`
    const value = item.value
    const data = item.data
    const color = item.color ?? DEFAULT_COLOR

    if (item.seriesType !== 'custom') {
      return `${content} <span style="color:${color};">●</span> ${seriesName}: \t ${parseFloat(value).toFixed(2)}</br>`
    }

    if (Array.isArray(data) && data.length === 3) {
      const hi_value = parseFloat(data[2]).toFixed(2)
      const lo_value = parseFloat(data[1]).toFixed(2)

      return `${content} <span style="color:${color};">●</span> ${seriesName}: \t [${lo_value}, ${hi_value}]<br>`
    }

    return `${content} <span style="color:${color};">●</span> ${seriesName}: \t ${Math.round(value)}<br>`
  }, tooltipContent)
}

// useful link for confidence intervals
// https://stackoverflow.com/questions/61724715/echarts-plot-the-variance-of-signals
export function renderItem(params, api) {
  const index = api.value(0)

  const lowerBound = api.value(1)
  const upperBound = api.value(2)

  const bandCoords = [
    api.coord([index, lowerBound]), // Bottom left
    api.coord([index + 1, lowerBound]), // Bottom right
    api.coord([index + 1, upperBound]), // Top right
    api.coord([index, upperBound]), // Top left
  ]

  return {
    type: 'polygon',
    shape: {
      points: echarts.graphic.clipPointsByRect(bandCoords, {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }),
    },
    style: api.style(
      api.style({
        fill: 'rgba(69, 170, 242, 0.3)',
      }),
    ),
  }
}
