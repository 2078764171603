import { Route, Routes } from 'react-router-dom'

import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import NavigateToError from '@/features/error/pages/NavigateToError'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { ResourceRouteInformation } from '@/features/resource/constants'
import BatterySteeringPage from '@/features/resource/pages/BatterySteeringPage'
import ResourceDetailsPage from '@/features/resource/pages/ResourceDetailsPage'
import ResourceGeneralPage from '@/features/resource/pages/ResourceGeneralPage'
import ResourceManualSteeringPage from '@/features/resource/pages/ResourceManualSteeringPage'
import ResourceSchedulerPage from '@/features/resource/pages/ResourceSchedulerPage'
import ResourceSteeringPage from '@/features/resource/pages/ResourceSteeringPage'

import ResourcesIndex from './pages/ResourcesIndex'
import { deserializeGlobalFilterSearchParamsObject } from './utils/globalFilters'

export default function ResourcesRoutes() {
  const { isEnabled } = useFeatureToggle()
  return (
    <Routes>
      <Route
        element={
          <DataGridSyncUrlManagerProvider
            deserializeGlobalFilterSearchParamsObject={deserializeGlobalFilterSearchParamsObject}
          >
            <ResourcesIndex />
          </DataGridSyncUrlManagerProvider>
        }
        path={ResourceRouteInformation.INDEX.routePath}
      />

      {isEnabled('FLEXPORTAL_RESOURCE_SCHEDULER_PAGE_ENABLED') && (
        <Route element={<ResourceSchedulerPage />} path={ResourceRouteInformation.RESOURCE_SCHEDULER.routePath} />
      )}
      <Route element={<ResourceDetailsPage />} path={ResourceRouteInformation.RESOURCE_DETAILS.routePath}>
        <Route index element={<ResourceGeneralPage />} />
        <Route element={<ResourceGeneralPage />} path={ResourceRouteInformation.RESOURCE_GENERAL.routePath} />
        <Route element={<ResourceSteeringPage />} path={ResourceRouteInformation.RESOURCE_STEERING.routePath} />
        <Route element={<BatterySteeringPage />} path={ResourceRouteInformation.RESOURCE_BATTERY_STEERING.routePath} />
        <Route
          element={<ResourceManualSteeringPage />}
          path={ResourceRouteInformation.RESOURCE_MANUAL_STEERING.routePath}
        />

        <Route element={<NavigateToError statusCode={404} />} path="*" />
      </Route>

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
