import { AddTaskOutlined } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { Status } from '@/features/bidding/constants'
import { ManualAcceptFlow } from '@/features/bidding/constants'
import { isManuallyAcceptable } from '@/features/bidding/utils/bidStatus'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { buildBidEditLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

interface ManuallyAcceptOptionProps {
  groupedBidParams: GroupedBidParams
  statuses: Status[]
}

const ManuallyAcceptOption = ({ groupedBidParams, statuses }: ManuallyAcceptOptionProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    statuses.some((status) => isManuallyAcceptable(status)) && (
      <MenuItem
        aria-label={t('bidding.options.manually_accept')}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          navigate(buildBidEditLink(ManualAcceptFlow.CREATE, groupedBidParams))
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <AddTaskOutlined />
          </Grid>
          <Grid item xs="auto">
            {t('bidding.options.manually_accept')}
          </Grid>
        </Grid>
      </MenuItem>
    )
  )
}

export default ManuallyAcceptOption
